import React from "react";
import { styled } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

import type { Agents } from "@/game-val/models/agents.mjs";
import { getHSLColor } from "@/game-val/utils.mjs";
import { classNames } from "@/util/class-names.mjs";

const CardFrame = styled("div")`
  position: relative;
  width: var(--width, 152px);
  height: var(--height, calc(var(--width, 160px) * 0.5));
  background: var(--shade8);
  border-radius: var(--br);

  &.without-overflow {
    overflow: hidden;

    .agent-image {
      height: 100%;
    }
  }

  &.cropped {
    ${mobile} {
      overflow: hidden;
    }

    .agent-image {
      ${mobile} {
        transform: translate(-50%, 20%);
      }
    }
  }

  &.mobile-hide-textwall {
    .agent-textwall-container {
      ${mobile} {
        display: none;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
  }

  &::before {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--background-color, "transparent");
    border-radius: var(--br);
    opacity: 0.2;
    z-index: 1;
  }

  .agent-image.hide {
    display: none;
  }

  .agent-image {
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 115%;
    width: auto;
    transform: translateX(-50%);
    /* mask-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), black 20%); */
    z-index: 2;
  }

  .agent-textwall-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  img.agent-textwall {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0);
    fill: var(--agent-color, var(--shade2));
    opacity: 0.75;
    transition: var(--transition);
    z-index: 1;
  }
`;

interface AgentCardProps {
  width?: number;
  height?: number;
  noBG?: boolean;
  cropped?: boolean;
  preserveTextwall?: boolean;
  withoutOverflow?: boolean;
  agent: Agents[number];
}

const AgentCard: React.FC<AgentCardProps> = ({
  width,
  height,
  noBG = false,
  cropped,
  preserveTextwall,
  withoutOverflow,
  agent,
}) => {
  const agentColor = getHSLColor(agent.color);

  return (
    <CardFrame
      style={{
        "--agent-color": agentColor ? `hsl(${agentColor})` : null,
        "--background-color":
          noBG || !agentColor ? "transparent" : `hsl(${agentColor})`,
        "--width": width ? `${width}px` : null,
        "--height": height ? `${height}px` : null,
      }}
      {...classNames(
        withoutOverflow && "without-overflow",
        !preserveTextwall && "mobile-hide-textwall",
        cropped && "cropped",
      )}
    >
      <div className="agent-textwall-container">
        <img
          className="agent-textwall"
          src={agent.images.textWall.url}
          alt={agent.key}
        />
      </div>
      <img src={agent.images.cutout.url} className="agent-image" />
    </CardFrame>
  );
};

export default AgentCard;
