import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { hardCodeURLs } from "@/app/constants.mjs";
import type { VideoModalProps } from "@/game-val/VideoModal.jsx";
import VideoModal from "@/game-val/VideoModal.jsx";
import BlitzPlay from "@/inline-assets/blitz-play.svg";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const VideoList = styled("div")`
  display: grid;
  grid-gap: var(--sp-4);
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  .video-link {
    position: relative;
    cursor: pointer;
    background: var(--shade8);
    &:hover {
      svg {
        transform: translate(-50%, -50%) scale(1.1);
      }
      img {
        opacity: 0.7;
        border-color: var(--agent-color);
      }
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      height: var(--sp-12);
      width: var(--sp-12);
      transform: translate(-50%, -50%);
      filter: drop-shadow(0 0 7px black);
      transition: var(--transition);
    }
  }
  img {
    display: block;
    width: 100%;
    border-radius: var(--br);
    border: 2px solid var(--shade4);
    opacity: 0.4;
    transition: var(--transition);
  }
`;

type YouTubeVideoDetails = Pick<
  VideoModalProps,
  "src" | "startTime" | "endTime"
>;

export default function AgentYoutubeVideos({ videos, name }) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [youtubeVideoDetails, setYoutubeVideoDetails] =
    useState<YouTubeVideoDetails | null>(null);
  const onModalClose = useCallback(() => {
    setShowModal(false);
  }, []);
  const {
    settings: {
      valorant: { muted },
    },
  } = useSnapshot(readState);

  const showYoutubeVideo = useCallback((e) => {
    const {
      videoid: yt,
      videostart: startTime,
      videoend: endTime,
    } = e.currentTarget.dataset || null;

    setYoutubeVideoDetails({
      src: { yt },
      startTime,
      endTime,
    });
    setShowModal(true);
  }, []);

  return (
    <>
      <Card
        title={t("val:matchesPlayedAsAgent", "Matches played as {{agent}}", {
          agent: name,
        })}
      >
        <VideoList className="card-container">
          {videos?.map((video) => {
            const url = new URL(video);
            const id = url.pathname.split("/").pop();
            const start = url.searchParams.get("start");
            const end = url.searchParams.get("end");
            const thumbnail = `${hardCodeURLs.YOUTUBE_THUMBNAIL}/vi/${id}/mqdefault.jpg`;
            return (
              <div
                key={id}
                className="video-link"
                data-videoid={id}
                data-videostart={start}
                data-videoend={end}
                onClick={showYoutubeVideo}
              >
                <img src={thumbnail} loading="lazy" />
                <BlitzPlay />
              </div>
            );
          })}
        </VideoList>
      </Card>

      {youtubeVideoDetails && (
        <VideoModal
          onModalClose={onModalClose}
          showModal={showModal}
          isMuted={muted}
          {...youtubeVideoDetails}
        />
      )}
    </>
  );
}
